/*CSS RESET*/
body,
header,
section,
input,
label,
select,
option,
textarea,
footer,
div,
form,
p,
h1,
h2,
h3,
h4,
h5,
h6,
img,
figure,
figcaption,
table,
td,
tr,
thead,
tbody,
ul,
ol,
li {
  padding: 0;
  margin: 0;
  list-style: none;
  border-collapse: collapse;
  border: none;
  font-family: sans-serif;
  position: relative;
  outline: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

:root {
  --sidebar-color: white;
  --header-height: 70px;
  --sidebar-width: 260px;
  --sidebar-minimized: 60px;
  --main-color: #282e52;
  --body-color: #f4f5fa;
  --transition-delay: 0.3s;
  --action-color: #e3642f;
}

a:visited {
  color: var(--main-color);
  font-style: unset;
}
body {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--body-color);
  color: var(--main-color);
}

/****************  SIDEBAR  *******************/
.side-bar {
  position: fixed;
  top: 0;
  width: var(--sidebar-width);
  height: 100vh;
  background-color: var(--sidebar-color);
  overflow: visible;
  z-index: 3;
  transition: var(--transition-delay);
}

header {
  height: 200px;
  color: white;
}

header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  overflow: visible;
  border-bottom: 1px solid var(--body-color);
  transition: var(--transition-delay);
}

header i {
  margin-left: 15px;
  font-size: 30px;
}
header h1 {
  margin-left: 15px;
  font-weight: 100;
}

header img {
  width: 80%;
  margin-left: 3px;
  transition: var(--transition-delay);
}

.small-logo {
  display: none;
  transition: var(--transition-delay);
}

.menu-toggle {
  position: absolute;
  color: var(--main-color);
  z-index: 25;
  left: 15px;
  font-size: 25px;
  cursor: pointer;
  transition: var(--transition-delay);
}

.menu-toggle:hover {
  color: var(--action-color);
  transition: var(--transition-delay);
  transform: scale(1.2);
}

.side-bar-menu {
}

.side-bar-menu ul {
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
}

.menu-item {
  display: block;
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: left;
  text-decoration: none;
  font-weight: 300;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 15px;
  transition: var(--transition-delay);
}
.menu-item a {
  color: var(--main-color);
}

.menu-header {
  display: block;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: left;
  text-decoration: none;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 15px;
  background-color: var(--body-color);
}
.menu-header span {
  margin-left: 15px;
  cursor: default;
}

.menu-item:hover {
  /* background-color: rgb(230, 234, 241); */
  transition: var(--transition-delay);
  transform: scale(1.1);
}
.menu-item:hover i,
.menu-item:hover span {
  color: var(--action-color);
  transition: var(--transition-delay);
}

.menu-item i {
  margin-left: 25px;
  color: var(--main-color);
}

.menu-item span {
  margin-left: 10px;
  font-weight: 400;
  color: var(--main-color);
}

.menu-item-active {
  border-left: 5px solid var(--action-color);
  width: calc(100% - 5px);
}
.menu-item-active i,
.menu-item-active span {
  color: var(--action-color);
}

/****************  NAVBAR  *******************/
.nav-bar {
  position: fixed;
  height: var(--header-height);
  left: var(--sidebar-width);
  top: 0;
  background-color: white;
  width: calc(100% - var(--sidebar-width));
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;
  /* box-shadow: 1px 2px 30px 2px rgba(0,0,0,0.1); */
  transition: var(--transition-delay);
}

.nav-bar i {
  color: #727272;
}
.nav-bar i:hover {
  color: var(--action-color);
}
.search-wrapper {
  /* margin-right: 15%; */
  border-radius: 4px;
  border: 1px solid rgb(223, 223, 223);
}
.search-wrapper input {
  padding: 4px;
  font-size: 20px;
  color: var(--main-color);
}

.search-wrapper input::placeholder {
  color: rgb(211, 211, 211);
}

.search-wrapper i {
  padding-right: 10px;
  color: rgb(224, 224, 224);
}

.profile-wrapper {
  position: relative;
  margin-right: 5px;
  cursor: pointer;
  padding: 25px;
}

.notification-wrapper {
  cursor: pointer;
  padding: 25px;
}

.profile-options {
  width: 150px;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 1px 2px 30px 2px rgba(0, 0, 0, 0.1);
  transform: translateX(200px);
  transition: var(--transition-delay);
}

.profile-wrapper:hover .profile-options {
  transform: translateX(1px);
  background-color: var(--body-color);
  transition: var(--transition-delay);
}

.profile-options li {
  padding: 3px 0;
  transition: var(--transition-delay);
  padding-left: 5px;
}
.profile-options li i {
  width: 20px;
}

.profile-options li:hover,
.profile-options li:hover i {
  transition: var(--transition-delay);
  color: var(--action-color);
}

/****************  Main Content  *******************/

.main {
  position: relative;
  margin-left: calc(var(--sidebar-width) + 20px);
  margin-right: 20px;
  margin-top: 90px;
  width: calc(100vw - var(--sidebar-width) - 50px);
  transition: var(--transition-delay);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.container {
}

/****************  Minimize Menu  *******************/

#menu-toggle:checked ~ .side-bar {
  width: 60px;
}

#menu-toggle:checked ~ .side-bar .logo {
  display: none;
}

#menu-toggle:checked ~ .side-bar .small-logo {
  display: block;
  transition: var(--transition-delay);
}

#menu-toggle:checked ~ .side-bar .menu-item span {
  display: none;
}
#menu-toggle:checked ~ .side-bar .menu-header span {
  display: none;
}

#menu-toggle:checked ~ .side-bar .menu-header::before {
  content: "...";
  font-size: 30px;
  position: relative;
  left: 25px;
}

#menu-toggle:checked ~ .side-bar header h1 {
  display: none;
}
#menu-toggle:checked ~ .nav-bar {
  left: var(--sidebar-minimized);
  width: calc(100% - var(--sidebar-minimized));
}

#menu-toggle:checked ~ .main {
  width: calc(100vw - var(--sidebar-minimized) - 50px);
  margin-left: calc(var(--sidebar-minimized) + 20px);
}

#menu-toggle:checked ~ .side-bar:hover {
  width: var(--sidebar-width);
}

#menu-toggle:checked ~ .side-bar:hover .menu-item span {
  display: unset;
}
#menu-toggle:checked ~ .side-bar:hover .menu-header span {
  display: unset;
}

#menu-toggle:checked ~ .side-bar:hover .menu-header::before {
  display: none;
}

#menu-toggle:checked ~ .side-bar:hover header i,
#menu-toggle:checked ~ .side-bar:hover header h1 {
  display: unset;
}

#menu-toggle:checked ~ .side-bar header {
  height: var(--header-height);
}

#menu-toggle:checked ~ .side-bar header {
  height: var(--header-height);
}

#menu-toggle:checked ~ .side-bar:hover header {
  height: 200px;
}
#menu-toggle:checked ~ .side-bar:hover .logo {
  display: block;
}

#menu-toggle:checked ~ .side-bar:hover .small-logo {
  display: none;
}

.row {
  display: flex;
  flex-direction: row;
  /* background-color: red; */
  width: 100%;
  justify-content: space-between;
}

.col {
  display: flex;
  flex-direction: column;
}
